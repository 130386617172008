import React, { useEffect, useRef } from 'react'
import style from './Home.module.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { NavLink, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

function Home2() {

    const isMobile = useMediaQuery({ maxWidth: 767 })
    const navigate = useNavigate()

    gsap.registerPlugin(ScrollTrigger, MotionPathPlugin)

    const initialSvg = useRef(null)
    const normalPageRef = useRef(null)
    const payoffRef = useRef(null)

    useEffect(() => {
        if (!isMobile) {
            const payOff = gsap
            // payOff.to(payoffRef.current, { opacity: 1, duration: 1 })
            const tlPayOff = payOff.timeline()
            tlPayOff.to(`.payoff>div:nth-child(1)`, {opacity: 1, duration: 1})
            tlPayOff.to(`.payoff>div:nth-child(2)`, {opacity: 1, duration: 1})
            tlPayOff.to(`.payoff>div:nth-child(3)`, {opacity: 1, duration: 1})


            const element = initialSvg.current
            const element2 = normalPageRef.current
            gsap.defaults({ ease: "none" })

            const pulses = gsap.timeline({
                defaults: {
                    opacity: 1,
                    scale: 2,
                    transformOrigin: 'center',
                    ease: "elastic(2.5, 1)"
                }
            })
            pulses.to(element.getElementsByClassName('ball02')[0], {}, 0.84)
            pulses.to(element.getElementsByClassName('text01'), { scale: 1, duration: 0.5, ease: "linear" }, 0.84)
            pulses.to(element.getElementsByClassName('ball03')[0], {}, 1.36)
            pulses.to(element.getElementsByClassName('text02'), { scale: 1, duration: 0.5, ease: "linear" }, 1.36)
            pulses.to(element.getElementsByClassName('ball04')[0], {}, 1.92)
            pulses.to(element.getElementsByClassName('text03'), { scale: 1, duration: 0.5, ease: "linear" }, 1.92)
            pulses.to(element.getElementsByClassName('ball01')[0], { scale: 50, duration: 0.5, ease: "linear" }, 2.58)

            const main = gsap.timeline({
                scrollTrigger: {
                    trigger: "#svg",
                    scrub: true,
                    start: "top 90%",
                    end: "bottom center",
                    
                    markers: false
                }
            })
            main
                .to(element.getElementsByClassName('ball01')[0], { opacity: 1, duration: 0.05 })
                .to(element.getElementsByClassName('theLine')[0], { strokeDashoffset: 0, duration: 4 }, 0)
                .to(element.getElementsByClassName('ball01')[0], {
                    motionPath: {
                        path: element.getElementsByClassName('theLine')[0],
                        align: element.getElementsByClassName('theLine')[0],
                        alignOrigin: [0.5, 0.5]
                    }, duration: 4
                }, 0)
                .add(pulses, 0)


            const normalPage = gsap.timeline({
                scrollTrigger: {
                    trigger: "#svg",
                    start: "center bottom",
                    end: "bottom bottom",
                    markers: false
                }
            })
            normalPage.to(element2.getElementsByTagName('h2')[0], { top: '-500px', duration: 0.5 })

            const normalPage2 = gsap.timeline({
                scrollTrigger: {
                    trigger: "#svg",
                    start: "center bottom",
                    end: "bottom bottom",
                    scrub: true,
                    markers: false
                }
            })
            normalPage2.to(element2.getElementsByTagName('h2')[0], { opacity: 1, scale: 1 }, 0)
        } else {
            gsap.to('.payoff1', {opacity: 1, duration: 2})
            gsap.to('.payoff2', {opacity: 1, duration: 2, delay: 1})
            gsap.to('.payoff3', {opacity: 1, duration: 2, delay: 2})

            const content = gsap.timeline({
                scrollTrigger: {
                    trigger: ".content",
                    start: "top center",
                    end: "center bottom",
                    markers: false
                }
            })
            content.to('.content>div:nth-child(1)>div:nth-child(1)', {opacity: 1, x: 10})
            content.to('.content>div:nth-child(1)>div:nth-child(2)', {opacity: 1, x: 10})
            content.to('.content>div:nth-child(2)>div:nth-child(1)', {opacity: 1, x: 10})
            content.to('.content>div:nth-child(2)>div:nth-child(2)', {opacity: 1, x: 10})
            content.to('.content>div:nth-child(3)>div:nth-child(1)', {opacity: 1, x: 10})
            content.to('.content>div:nth-child(3)>div:nth-child(2)', {opacity: 1, x: 10})

        }
    }, [isMobile])

    if (isMobile) {
        return (
            <>
                <div className={style['payoff-mobile']}>
                    <div style={{ textAlign: 'center', color: 'black', opacity: 0}} className='payoff1'>Hi, I'm Salvatore </div>
                    <div style={{ textAlign: 'center', color: 'black', opacity: 0}} className='payoff2'>and</div>
                    <div style={{ textAlign: 'center', color: 'black', opacity: 0}} className='payoff3'>this is my portfolio</div>
                </div>
                <div className={style.content + ' content'}>
                    <div>
                        <div style={{ textAlign: 'center', opacity: 0}} className='text01'>You won't find anything special</div>
                        <div style={{ textAlign: 'center', opacity: 0}} className='text02'>on this homepage. Just some animation</div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'center', opacity: 0}} className='text03'>To see what I can do I suggest you</div>
                        <div style={{ textAlign: 'center', opacity: 0}} className='text04'>go to the portfolio section</div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'center', opacity: 0}} className='text05'>Then go to the contact section</div>
                        <div style={{ textAlign: 'center', opacity: 0}} className='text06'>I will be happy to start a new journey with you</div>
                    </div>
                </div>
                <div>
                    <h2 style={{ fontFamily: 'Eagle Lake', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", scale: 0, opacity: 0, maxWidth: '50%', position: 'relative', translate: "-50%", left: '50%', whiteSpace: "nowrap" }}>
                        Go to
                    </h2>

                </div>
            </>
        )
    }

    return (
        <>
            <div ref={payoffRef} className={style.payoff + ' payoff'}>
                <div style={{ textAlign: 'center', opacity: 0, color: 'white' }}>Salvatore Bosco </div>
                <div style={{ textAlign: 'center', opacity: 0, color: 'white' }}></div>
                <div style={{ textAlign: 'center', opacity: 0, color: 'white' }}>this is my portfolio</div>
            </div>
            <svg ref={initialSvg} id='svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 1200'>
                {/* <path className={style['line01'] + " " + style['line']} d="M 10 200 600 200"></path>
                <path className={style['line02'] + " " + style['line']} d="M 10 400 600 400"></path>
                <path className={style['line03'] + " " + style['line']} d="M 10 600 600 600"></path>
                <path className={style['line04'] + " " + style['line']} d="M 10 800 600 800"></path>
                <path className={style['line05'] + " " + style['line']} d="M 10 1000 600 1000"></path> */}
                <text fill='white' style={{ opacity: 0 }} className={'text01 ' + style.text01} x={'320'} y={'190'}>You won't find anything special</text>
                <text fill='white' style={{ opacity: 0 }} className={'text01 ' + style.text01} x={'325'} y={'210'}>on this homepage. Just some animation</text>
                <text fill='white' style={{ opacity: 0 }} className={'text02 ' + style.text02} x={'10'} y={'390'}>To see what I can do I suggest you</text>
                <text fill='white' style={{ opacity: 0 }} className={'text02 ' + style.text02} x={'10'} y={'410'}>go to the portfolio section</text>
                <text fill='white' style={{ opacity: 0 }} className={'text03 ' + style.text03} x={'260'} y={'590'}>Then go to the contact section</text>
                <text fill='white' style={{ opacity: 0 }} className={'text03 ' + style.text03} x={'260'} y={'610'}>I will be happy to start a new journey with you</text>

                <path className={style.theLine + " theLine"}
                    d='M -5,0
                   Q 450 230 300 450
                   T 130 750
                   Q 100 850 300 1000
                   T 150 1200'
                    fill='none' stroke='#a88d2e' strokeWidth={"10px"}></path>

                <circle fill='#3333ff' className={'ball01 ball ' + style.ball01} r={'20'} cx={'50'} cy={'100'}></circle>
                <circle fill='#3333ff' className={'ball02 ball ' + style.ball02} r={'20'} cx={'278'} cy={'201'}></circle>
                <circle fill='#3333ff' className={'ball03 ball ' + style.ball03} r={'20'} cx={'327'} cy={'401'}></circle>
                <circle fill='#3333ff' className={'ball04 ball ' + style.ball04} r={'20'} cx={'203'} cy={'601'}></circle>
            </svg>
            <div ref={normalPageRef} id='normalPage' className={'h-0'}>
                    <h2 style={{ fontFamily: 'Eagle Lake', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", scale: 0, opacity: 0, maxWidth: '50%', position: 'relative', translate: "-50%", left: '50%', whiteSpace: "nowrap" }}>
                        Go to <NavLink color='white' to={'/portfolio'} > My Porfolio</NavLink>
                    </h2>
            </div>
        </>
    )
}

export default Home2