import React, { useEffect } from 'react'
import './Preloader.css'
import { isActive, preloaderAnim } from '../../animations'
import { useNavigate } from 'react-router-dom'
function Preloader() {

    const navigate = useNavigate()

    useEffect(() => {
        preloaderAnim(navigate)
        // navigate('/home')
    }, [])

    return (
        <>
            <div className='preloader'>
                <div className='texts-container'>
                    <span>Welcome</span>
                    <span>to</span>
                    <span>my</span>
                </div>
                <div className='netflix'>
                    Portfolio
                </div>
                <div className='scroll'>scroll</div>
                <div className='div'></div>
            </div>

        </>
    )
}

export default Preloader