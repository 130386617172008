
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './routes/Home/Home';
import Home2 from './routes/Home/Home2';
import Contacts from './routes/Contacts/Contacts';
import Portfolio from './routes/Portfolio/Portfolio';
import MainLayout from './components/MainLayout/MainLayout';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Project from './routes/Project/Project';
import AboutMe from './routes/AboutMe/AboutMe';
import Preloader from './components/Preloader/Preloader';


const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Preloader />
        },
        {
          path: "/home",
          element: <Home />
        },
        {
          path: "/contacts",
          element: <Contacts />
        },
        {
          path: "/portfolio",
          element: <Portfolio />
        },
        {
          path: '/about-me',
          element: <AboutMe />
        }
      ]
    },
    {
        path: '/projects/:id',
        element: <Project />,
        errorElement: <ErrorPage />,
    }
  ]
)

function App() {

  

  return (
    <>
      
      <RouterProvider router={router} />
    </>

  );
}

export default App;
