import { Typography } from '@mui/material';
import style from './ImageCoverPost.module.css'
import { useMediaQuery } from 'react-responsive';
import Header from '../Header/Header';

const ImageCoverPost = ({ image, title }) => {

  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <div className={style.imageContainer} style={isMobile ? { height: '20vh' } : { height: '80vh' }}>
      <img src={image} alt={title} className={style['img_cover']} />
      <div className={style['header']}>
        <Header />
      </div>
      <Typography variant="h4" className={style.textOverlay}>
        {title}
      </Typography>
    </div>
  );
};

export default ImageCoverPost;
