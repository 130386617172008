import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab"
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import { Typography } from "@mui/material";

function Life({ type }) {
    if (type === 'work') {
        return (
            <Timeline position="alternate">
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        7:00 am
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                            <FastfoodIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2, color: 'grey' }}>
                        <Typography variant="h6" component="span">
                            Eat and get ready
                        </Typography>
                        <Typography sx={{ color: 'grey' }}>Because you need strength</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                    >
                        9:00 am
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                            <LaptopMacIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2, color: "grey" }}>
                        <Typography variant="h6" component="span">
                            Code
                        </Typography>
                        <Typography sx={{ color: "grey" }}>Because it&apos;s awesome!</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant="body2"
                        color="text.secondary"
                    >
                        11:00 pm
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary" variant="outlined">
                            <HotelIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography sx={{ color: "grey" }} variant="h6" component="span">
                            Sleep
                        </Typography>
                        <Typography sx={{ color: "grey" }}>Because you need rest</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                        <TimelineDot color="secondary">
                            <RepeatIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography sx={{ color: "grey" }} variant="h6" component="span">
                            Repeat
                        </Typography>
                        <Typography sx={{ color: "grey" }}>Because this is the life you love!</Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        )
    }

    if (type === 'study') {
        return (
            <div style={{ color: 'grey' }}>"Section STUDY" in progress</div>
        )
    }

    if (type === 'personal') {
        return (
            <div style={{ color: 'grey' }}>"Section PERSONAL" in progress</div>
        )
    }

    return <div>Error...</div>
}

export default Life