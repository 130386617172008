import React from 'react'
import Home1 from './Home1'
import Home2 from './Home2'

function Home() {
  return (
    <>
    <Home1 />
    <Home2 />
    </>
  )
}

export default Home