import gsap from 'gsap'

const tl = gsap.timeline();



export const preloaderAnim = async (navigate) => {
    tl.to('.preloader',{
        duration: 0,
        display: "flex"
    })
    .to(".texts-container", {
        duration: 0,
        opacity: 1,
        ease: "Power3.easeOut",
    })
    .from(".texts-container span", {
        duration: 1.5,
        y: 70,
        skewY: 10,
        stagger: 0.4,
        ease: "Power3.easeOut",
    })
    .to(".texts-container span", {
        duration: 1,
        y: 70,
        skewY: -20,
        stagger: 0.2,
        ease: "Power3.easeOut",
    })
    .from('.netflix', {
        duration: 4,
        scale: 30,
        opacity: 0
    })
    .to('.netflix', {
        duration: 1,
        opacity: 0,
        onComplete: ()=>{
            navigate('/home')
        }
    })
    .to(".preloader", {
        duration: 1.5,
        height: "0vh",
        ease: "Power3.easeOut",
    }, '-=0.5')
    .to(".preloader", {
        duration: 0,
        display: "none",
    });

    return new Promise((resolve, reject) => {
        if(!tl.isActive()){
            resolve(true)
        }
    })

    //   .to('.scroll', {
    //     duration: 1,
    //     opacity: 1
    // }, '+=1')
    // .to('.div', {
    //     scrollTrigger: {
    //         trigger: ".div",
    //         pin: true,   // pin the trigger element while active
    //         start: "top center", // when the top of the trigger hits the top of the viewport
    //         end: "+=500", // end after scrolling 500px beyond the start
    //         scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    //         markers: true
    //     },
    //     width: '100%',
    //     height: '100%'
    // })

}

export const isActive = tl.isActive()