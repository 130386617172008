import React, { useEffect, useRef, useState } from 'react'
import CardPortfolio from '../../components/CardPortfolio/CardPortfolio'
// import style from './Portfolio.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/free-mode';

import { EffectCoverflow, Mousewheel, FreeMode } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';
// let ProjectsDummys = [
//     {
//         img: 'https://picsum.photos/200',
//         title: '3D Art Gallery',
//         description: 'I have been trying to recreate an art exhibition in 3d using Three.js',
//         link: ''
//     },
//     {
//         img: 'https://picsum.photos/200',
//         title: 'GSAP + clip-path',
//         description: 'I have created this product section for a company with the help of the GSAP library and...',
//         link: ''
//     },
//     {
//         img: 'https://picsum.photos/200',
//         title: 'Radio app',
//         description: 'Building a radio app with React-Native from scratch',
//         link: ''
//     },
//     {
//         img: 'https://picsum.photos/200',
//         title: 'kwp2000 library',
//         description: 'Ho cercato di ricreare una mostra d\'arte in 3d utilizzando Three.js',
//         link: ''
//     },
//     {
//         img: 'https://picsum.photos/200',
//         title: 'Galleria D\'arte',
//         description: 'Ho cercato di ricreare una mostra d\'arte in 3d utilizzando Three.js',
//         link: ''
//     },
//     {
//         img: 'https://picsum.photos/200',
//         title: 'Galleria D\'arte',
//         description: 'Ho cercato di ricreare una mostra d\'arte in 3d utilizzando Three.js',
//         link: ''
//     }
// ]

function Porfolio() {
    const [projects, setProjects] = useState([])
    const swiperRef = useRef(null);
    // const [posts, setPosts] = useState(null)
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {


        const fetchPost = async () => {
            try {
                const response = await fetch('https://boscosalvatore.it/blog/wp-json/wp/v2/posts');
                const data = await response.json();
                setProjects(data);
                setIsLoading(false)
            } catch (error) {
                console.error(error);
            }
        };

        fetchPost();

    }, []);

    useEffect(() => {

        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        
        return () => {
            document.getElementsByTagName('body')[0].style.overflow = 'auto'
        }
    }, [])
    function pickImg(link) {

        if (link === '') {
            return ''
        }

        const fetchImg = async () => {
            try {
                const response = await fetch(link, { method: 'GET', mode: 'cors' });
                const data = await response.json();
                return data
            } catch (error) {
                console.error(error);
            }
        };

        const res = fetchImg();
        return res
    }
    
    if(isLoading){
        return (
            <div className={'w-full flex items-center justify-center h-[100vh] text-[#836e24]'}>
                Caricamento...
            </div>
        )
    }

    window.scroll(0, 0)
    return (
        <Swiper
            ref={swiperRef}
            effect={'coverflow'}
            grabCursor={true}
            spaceBetween={50}
            mousewheel={true}
            freeMode={true}
            slidesPerView={2}
            navigation={true}
            centeredSlides={true}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 10,
                modifier: 1,
                slideShadows: false,
            }}
            modules={[EffectCoverflow, Mousewheel, FreeMode]}
            loop={projects.length < 4 ? false : true}
            className="mySwiper"
            style={{ width: '100%', height: '100vh' }}
        >
            {projects.map((project, index) => {
                console.log(project?.title?.rendered, project)
                return (
                    <SwiperSlide key={index} style={isMobile ? { maxHeight: '75%', minWidth: '300px' } : { maxHeight: '100%', width: 'fit-content' }}>
                        <CardPortfolio
                            img={async () => { return pickImg(project._links['wp:featuredmedia'] ? project._links['wp:featuredmedia'][0].href : "") }}
                            title={project.title.rendered}
                            description={project.excerpt.rendered}
                            link={project.id}
                            index={index} />
                    </SwiperSlide>
                )
            })}
        </Swiper>

    )
}

export default Porfolio

